import {Component, OnInit} from '@angular/core';
import { Nanium } from "nanium/core";
import { NaniumConsumerBrowserHttp } from "nanium/managers/consumers/browserHttp";
import { WebRequestInterceptorService } from "../services/security/web-request-interceptor.service";
import { WebAuthenticationService } from "../services/security/web-authentication.service";
import {NavigationEnd, Router} from "@angular/router";
import { User } from "../../../server/entities/user.entity";
import {
  BaseSecurityUserHasPermissionRequest
} from "../../../server/services/base/security/userHasPermission.contract";
import { userPermissions } from "../../../server/entities/user-role.entity";
import {FacilityQueryRequest} from "../../../server/services/facility/query.contract";
import {FacilityUpdateRequest} from "../../../server/services/facility/update.contract";
import {NewsQueryRequest} from "../../../server/services/news/query.contract";
import {GatheringQueryRequest} from "../../../server/services/gathering/query.contract";
import {UserQueryRequest} from "../../../server/services/user/query.contract";
import {UserUpdateRequest} from "../../../server/services/user/update.contract";
import {NewsUpdateRequest} from "../../../server/services/news/update.contract";
import {GatheringUpdateRequest} from "../../../server/services/gathering/update.contract";

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {
  public user: User;

  public showAdminMenu: boolean = false;
  public ownedFacility: string;

  public adminPages = [
    { title: 'Nutzer*innen', url: '/admin/users', icon: 'person' },
    { title: 'Rechte', url: '/admin/permissions', icon: 'key' },
    { title: 'Einrichtungen', url: '/admin/facilities', icon: 'storefront' },
    { title: 'Beratungskategorien', url: '/admin/consulting-categories', icon: 'albums' },
    // { title: 'Beratungsangebote', url: '/admin/consulting-offers', icon: 'book' },
    { title: 'Veranstaltungen', url: '/admin/gatherings', icon: 'calendar' },
    { title: 'News', url: '/admin/news', icon: 'newspaper' },
    { title: 'Life Hacks', url: '/admin/life-hacks', icon: 'basketball' },
    // { title: 'für Macher*innen', url: '/admin/participation-offers', icon: 'bulb' },
  ];

  public creatorPages = [
    { title: 'deine Einrichtung', url: `/admin/facilities/my-facility`, icon: 'storefront' },
    { title: 'deine Subscriber', url: '/admin/users', icon: 'person' },
    // { title: 'deine Beratungsangebote', url: '/admin/consulting-offers', icon: 'book' },
    { title: 'deine Veranstaltungen', url: '/admin/gatherings', icon: 'calendar' },
    { title: 'deine News', url: '/admin/news', icon: 'newspaper' },
    // { title: 'deine Life Hacks', url: '/admin/life-hacks', icon: 'basketball' }
  ];

  constructor(
    private webRequestInterceptorService: WebRequestInterceptorService,
    private webAuthenticationService: WebAuthenticationService,
    private router: Router,
  ) {
  }

  async ngOnInit() {
    let serverBaseUrl: string = '';
    if (location.port === '4200') {
      serverBaseUrl = 'http://' + location.host.replace(':4200', ':3000');
    }
    if (location.port === '44343') {
      serverBaseUrl = 'http://' + location.host.replace(':44343', ':3000');
    }
    await Nanium.addManager(new NaniumConsumerBrowserHttp({
      apiUrl: serverBaseUrl + '/api',
      apiEventUrl: serverBaseUrl + '/events',
      onServerConnectionRestored: () => {
        console.log('server connection restored');
        // when the clients could not reach the server for some time,
        // you should reload all stuff you need because maybe you missed some events
      },
      eventSubscriptionSendInterceptors: [],
      requestInterceptors: [ this.webRequestInterceptorService ],
      handleError: async (error: any) => {
        if (
          error === 'unauthorized'
          || error instanceof Error && error.message == 'unauthorized'
        ) {
          await this.router.navigate(['/login']);
        }
      }
    }));

    await this.webAuthenticationService.init();
    this.user = await this.webAuthenticationService.getUser();

    if (await this.hasAdminPermission()) {
      this.showAdminMenu = true;
    }

    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        this.reloadUser();
      }
    });
  }

  async reloadUser() {
    this.user = await this.webAuthenticationService.getUser();
    this.showAdminMenu = await this.hasAdminPermission();
  }

  async logout() {
    await this.webAuthenticationService.logout();
  }

  async hasAdminPermission() {
    return await new BaseSecurityUserHasPermissionRequest({
      permission: userPermissions.accessAdminBackend
    }).execute();
  }

  async compressImages() {
    let facilities = await new FacilityQueryRequest().execute();
    for (let facility of facilities) {
      console.log(`compressing facility ${facility.name}`);
      if (facility.logo) {
        facility.logo = await this.rescaleImage(facility.logo, 160);
      }
      if (facility.titlePicture) {
        facility.titlePicture = await this.rescaleImage(facility.titlePicture, 400);
      }
      await new FacilityUpdateRequest(facility).execute();
    }

    let news = await new NewsQueryRequest().execute();
    for (let newsItem of news) {
      if (newsItem.titleImage) {
        console.log(`compressing news ${newsItem.headline}`);
        newsItem.titleImage = await this.rescaleImage(newsItem.titleImage, 400);
        await new NewsUpdateRequest(newsItem).execute();
      }
    }

    let gatherings = await new GatheringQueryRequest().execute();
    for (let gathering of gatherings) {
      if (gathering.titlePicture) {
        console.log(`compressing gathering ${gathering.name}`);
        gathering.titlePicture = await this.rescaleImage(gathering.titlePicture, 400);
        await new GatheringUpdateRequest(gathering).execute();
      }
    }

    let users = await new UserQueryRequest().execute();
    for (let user of users) {
      if (user.profilePicture) {
        console.log(`deleting picture of user ${user.username}`);
        user.profilePicture = '';
        await new UserUpdateRequest(user).execute();
      }
    }
  }

  rescaleImage(src, maxWidth): Promise<string> {
    return new Promise((res, rej) => {
      const img = new Image();
      img.src = src;
      img.onload = () => {
        let {newWidth, newHeight} = this.computeNewImageSize(img, maxWidth);

        const elem = document.createElement('canvas');
        elem.width = newWidth;
        elem.height = newHeight;

        const ctx = elem.getContext('2d');
        ctx.drawImage(img, 0, 0, newWidth, newHeight);
        const data = ctx.canvas.toDataURL('image/jpeg');
        res(data);
      }
      img.onerror = error => rej(error);
    })
  }

  private computeNewImageSize(img: HTMLImageElement, maxWidth) {
    const oldWidth = img.width;
    const oldHeight = img.height;
    let newWidth: number;
    if (oldWidth < maxWidth) {
      newWidth = oldWidth;
    } else {
      newWidth = maxWidth;
    }
    let resizeFactor: number = oldWidth / newWidth;
    const newHeight: number = oldHeight / resizeFactor;
    return {newWidth, newHeight};
  }
}

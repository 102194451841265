import { NaniumObject, Type } from "nanium/objects";
import { Facility } from "./facility.entity";
import { FacilityGetRequest } from "../services/facility/get.contract";

export class News extends NaniumObject<News> {
  static strictDefault: boolean = true;

  @Type(String) id?: string;
  @Type(String) headline?: string;
  @Type(String) content?: string;

  @Type(String) titleImage?: string;
  @Type(String) titleVideo?: string;

  @Type(String) authorId?: string;
  @Type(Date) dateCreated?: Date;
  @Type(Date) datePublished?: Date;
  @Type(Boolean) published?: boolean;

  @Type(String) owningFacility?: string;
  @Type(Facility) owningFacilityObj?: Facility;

  async loadOwningFacility() {
    this.owningFacilityObj = await new FacilityGetRequest({id: this.owningFacility}).execute();
  }
}

<ion-app>
  <ion-split-pane contentId="main-content">
    <ion-menu contentId="main-content" type="overlay" side="start">

      <ion-header [translucent]="true">
        <ion-toolbar>
<!--          <ion-thumbnail slot="end">-->
<!--            <ion-img style="margin-right: 15px;" src="assets/icon/favicon.png"></ion-img>-->
<!--          </ion-thumbnail>-->
          <ion-title slot="start">Yoggl - Jugendapp Sachsen</ion-title>
<!--          <ion-buttons slot="end">-->
<!--            <ion-button color="secondary" href="/admin/consulting-offers/create">Beratungsangebot anlegen</ion-button>-->
<!--          </ion-buttons>-->
        </ion-toolbar>
      </ion-header>

      <ion-content>

        <div class="main-menu">

          <div>

            <div class="menu-logo-wrapper">
              <ion-img class="menu-logo" src="assets/logo-yoggl.svg"></ion-img>
            </div>

            <ion-list *ngIf="showAdminMenu">
              <ion-list-header>
                <ion-text>Backend für Admins</ion-text>
              </ion-list-header>
              <ion-menu-toggle auto-hide="false" *ngFor="let p of adminPages; let i = index">
                <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
                  <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>

            <ion-list *ngIf="!showAdminMenu">
              <ion-list-header>
                <ion-text>Backend für Einrichtungsbetreiber</ion-text>
              </ion-list-header>
              <ion-menu-toggle auto-hide="false" *ngFor="let p of creatorPages; let i = index">
                <ion-item routerDirection="root" [routerLink]="[p.url]" lines="none" detail="false" routerLinkActive="selected">
                  <ion-icon slot="start" [ios]="p.icon + '-outline'" [md]="p.icon + '-sharp'"></ion-icon>
                  <ion-label>{{ p.title }}</ion-label>
                </ion-item>
              </ion-menu-toggle>
            </ion-list>

          </div>







          <ion-list>
            <ion-list>
              <ion-list-header>
                Profileinstellungen
              </ion-list-header>
              <ion-item routerLink="/admin/change-password" lines="none">
                <ion-icon slot="start" name="key-outline"></ion-icon>
                <ion-label>Passwort ändern</ion-label>
              </ion-item>
              <ion-item *ngIf="user?.isAdmin && (user?.username === 'SyscoreAdmin' || user?.username === 'admin')" lines="none" routerLink="/" (click)="compressImages()">
                <ion-icon slot="start" name="images-outline"></ion-icon>
                <ion-label>Bilder komprimieren</ion-label>
              </ion-item>
            </ion-list>
            <ion-item lines="none">
              <ion-text>
                {{ user ? 'Du bist eingeloggt als ' + user.username + '.' : '-' }}
              </ion-text>
              <ion-button (click)="logout()" slot="end">
                <ion-icon color="white" name="log-out-outline"></ion-icon>
              </ion-button>
            </ion-item>
          </ion-list>

        </div>

      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content">
    </ion-router-outlet>
  </ion-split-pane>
</ion-app>

import { NaniumObject, RequestType, Type } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { Facility } from "../../entities/facility.entity";
import {ConsultingTag} from "../../entities/types/facility/ConsultingTag";
import {GeoLocationFilterType} from "../../entities/types/getLocationFilter.type";

export class FacilityQueryRequestBody extends NaniumObject<FacilityQueryRequestBody> {
	@Type(Array, String) idList?: string[];
	@Type(Boolean) isConsulting?: boolean;
	@Type(Boolean) isClub?: boolean;
	@Type(ConsultingTag) consultingTags?: ConsultingTag | any;
	@Type(GeoLocationFilterType) locationFilter: GeoLocationFilterType;
	@Type(Boolean) excludeMediaFiles?: boolean;
}

@RequestType({
	responseType: Facility,
	genericTypes: { TRequestBody: FacilityQueryRequestBody },
	scope: 'public'
})
export class FacilityQueryRequest extends ServiceRequestBase<FacilityQueryRequestBody | any, Facility[]> {
	static serviceName: string = 'NaniumDemo:facility/query';
}

import { NaniumObject, RequestType } from 'nanium/objects';
import { ServiceRequestBase } from '../serviceRequestBase';
import { News } from "../../entities/news.entity";

export class NewsQueryRequestBody extends NaniumObject<NewsQueryRequestBody> {
}

@RequestType({
	responseType: News,
	genericTypes: { TRequestBody: NewsQueryRequestBody },
	scope: 'public'
})
export class NewsQueryRequest extends ServiceRequestBase<NewsQueryRequestBody, News[]> {
	static serviceName: string = 'NaniumDemo:news/query';
}
